import Table from 'react-bootstrap/Table';
import './TrustedSites.css';

function SitesTable() {
    return (
        <div>
            <Table bordered hover size="lg" responsive="lg">
                <thead>
                    <tr>
                        <th>Website Name</th>
                        <th>Website Domain</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>GOV Website</td>
                        <td><a href='https://gov.nugmyanmar.org'>https://gov.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Defence</td>
                        <td><a href='https://mod.nugmyanmar.org'>https://mod.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Health</td>
                        <td><a href='https://moh.nugmyanmar.org'>https://mod.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Planning, Finance and Investment</td>
                        <td><a href='https://mopfi.nugmyanmar.org'>https://mopfi.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Foreign Affairs</td>
                        <td><a href='https://mofa.nugmyanmar.org'>https://mofa.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Education</td>
                        <td><a href='https://moe.nugmyanmar.org'>https://moe.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Home Affairs and Immigration</td>
                        <td><a href='https://mohai.nugmyanmar.org'>https://mohai.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Federal Union Affairs</td>
                        <td><a href='https://mofua.nugmyanmar.org'>https://mofua.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Humanitarian Affairs and Disaster Management</td>
                        <td><a href='https://mohadm.nugmyanmar.org'>https://mohadm.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Human Rights</td>
                        <td><a href='https://mohr.nugmyanmar.org'>https://mohr.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Natural Resources and Environmental Conservation</td>
                        <td><a href='https://monrec.nugmyanmar.org'>https://monrec.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of International Cooperation</td>
                        <td><a href='https://moic.nugmyanmar.org'>https://moic.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Women, Youths and Children Affairs</td>
                        <td><a href='https://mowyca.nugmyanmar.org'>https://mowyca.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Labour</td>
                        <td><a href='https://mol.nugmyanmar.org'>https://mol.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Justice</td>
                        <td><a href='https://moj.nugmyanmar.org'>https://moj.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Communications, Information & Technology</td>
                        <td><a href='https://mocit.nugmyanmar.org'>https://mocit.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Electricity & Energy</td>
                        <td><a href='https://moee.nugmyanmar.org'>https://moee.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Commerce</td>
                        <td><a href='https://moc.nugmyanmar.org'>https://moc.nugmyanmar.org</a></td>
                    </tr>
                </tbody>
            </Table>
            <Table bordered hover size="lg" responsive="sm">
                <thead>
                    <tr>
                        <th>Website List</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>GOV Website</td>
                    </tr>
                    <tr>
                        <td><a href='https://gov.nugmyanmar.org'>https://gov.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Defence</td>
                    </tr>
                    <tr>
                        <td><a href='https://mod.nugmyanmar.org'>https://mod.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Health</td>
                    </tr>
                    <tr>
                        <td><a href='https://moh.nugmyanmar.org'>https://mod.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Planning, Finance and Investment</td>
                    </tr>
                    <tr>
                        <td><a href='https://mopfi.nugmyanmar.org'>https://mopfi.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Foreign Affairs</td>
                    </tr>
                    <tr>
                        <td><a href='https://mofa.nugmyanmar.org'>https://mofa.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Education</td>
                    </tr>
                    <tr>
                        <td><a href='https://moe.nugmyanmar.org'>https://moe.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Home Affairs and Immigration</td>
                    </tr>
                    <tr>
                        <td><a href='https://mohai.nugmyanmar.org'>https://mohai.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Federal Union Affairs</td>
                    </tr>
                    <tr>
                        <td><a href='https://mofua.nugmyanmar.org'>https://mofua.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Humanitarian Affairs and Disaster Management</td>
                    </tr>
                    <tr>
                        <td><a href='https://mohadm.nugmyanmar.org'>https://mohadm.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Human Rights</td>
                    </tr>
                    <tr>
                        <td><a href='https://mohr.nugmyanmar.org'>https://mohr.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Natural Resources and Environmental Conservation</td>
                    </tr>
                    <tr>
                        <td><a href='https://monrec.nugmyanmar.org'>https://monrec.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of International Cooperation</td>
                    </tr>
                    <tr>
                        <td><a href='https://moic.nugmyanmar.org'>https://moic.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Women, Youths and Children Affairs</td>
                    </tr>
                    <tr>
                        <td><a href='https://mowyca.nugmyanmar.org'>https://mowyca.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Labour</td>
                    </tr>
                    <tr>
                        <td><a href='https://mol.nugmyanmar.org'>https://mol.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Justice</td>
                    </tr>
                    <tr>
                        <td><a href='https://moj.nugmyanmar.org'>https://moj.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Communications, Information & Technology</td>
                    </tr>
                    <tr>
                        <td><a href='https://mocit.nugmyanmar.org'>https://mocit.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Electricity & Energy</td>
                    </tr>
                    <tr>
                        <td><a href='https://moee.nugmyanmar.org'>https://moee.nugmyanmar.org</a></td>
                    </tr>
                    <tr>
                        <td>Ministry of Commerce</td>
                    </tr>
                    <tr>
                        <td><a href='https://moc.nugmyanmar.org'>https://moc.nugmyanmar.org</a></td>
                    </tr>
                </tbody>
            </Table>
        </div>
        //end

    );
}

export default SitesTable;