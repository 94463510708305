import React, { Component, Image, Table } from 'react';
import { Container } from 'react-bootstrap';
import emblem from "../../Images/emblem.webp";
import TrustedSitesList from './TrustedSitesList';
import './TrustedSites.css';

export default class TrustedWebsites extends Component {

    render() {
        return (
            <Container fluid className='p-0'>
                <div className='tw-banner'>
                    <img src={emblem} width={120} />
                    <h2 className='tctitle'>National Unity Government of Myanmar
                    </h2>
                </div>
                <div className='tnctou'>
                    <div className='tnctxt container text-center tw-width'>
                        <h1 className='tnctxttitle lh-lg text-center'>Trusted Sites</h1>
                        <p className='lh-lg text-center'>Learn to identify trustworthy websites. Look out for the .nugmyanmar.org URL in Government websites. We had previously used non .nugmyanmar.org URLs but are moving most Government websites to .nugmyanmar.org to make it easier for you to identify them. </p>
                        <p className='lh-lg text-center'>In general, do not trust any links purporting to be from official Government sources but do not come from a .nugmyanmar.org URL, unless you are already familiar with the website. If the website looks like a Government website but does not contain .nugmyanmar.org, you can verify it by checking this list of Government associated websites.</p>
                        {/* <p className='lh-lg text-center'>Go.nugmyanmar.org - short link for official Government websites</p>

                        <p className='lh-lg text-center'>GoEdu.nugmyanmar.org - short link for education institution websites</p> */}
                    </div>
                </div>

                <Container className='tw-width'>
                    <TrustedSitesList />
                </Container>

                {/* <div className='footer'>
                    <code className='codecolor'>It was developed by MoL-ICT with a huge love for our citizens.</code>
                </div> */}

            </Container>
        );
    }

}