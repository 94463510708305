import React, { Component, Image } from 'react';
import { Container } from 'react-bootstrap';
import emblem from "../../Images/emblem.webp";
import './TermOfUse.css';

export default class TermOfUse extends Component {

    render() {
        return (
            <Container fluid className='p-0'>
                {/* <TopBar /> */}
                <div className='tw-banner'>
                    <br></br>
                    <img src={emblem} width={120} />
                    <h2 className='tctitle'>Privacy Policy
                    </h2>
                    <br></br>
                </div>
                <div className='tnctou'>
                    <div className='tnctxt container'>
                        <h4 className='tnctxttitle lh-lg'>၁။ အထွေထွေ</h4>
                        <p className='lh-lg'>ဤသဘောတူညီချက်တွင် ကိုယ်ရေးကိုယ်တာအချက်အလက် ကောက်ယူခြင်းဆိုင်ရာ သဘောတူညီချက်ပါဝင်သည်။ </p>
                        <p className='lh-lg'>NUG Websites ဆိုသောစကားရပ်အား “ကျွန်ုပ်တို့”၊ “ကျွန်ုပ်တို့အား”၊ “ကျွန်ုပ်တို့၏” ဟူ၍ရည်ညွှန်းဖော်ပြမည်။</p>
                        <p className='lh-lg'>NUG Websites ဆိုသည်မှာ အမျိုးသားညီညွတ်ရေးအစိုးရ မှ ထုတ်ပြန်ကြေညာချက်များ၊ သတင်းများ စသည့် အချက်အလက်များအား အများပြည်သူသိရှိနိုင်စေရန် လွှင့်တင်ထားသည့် အီလက်ထရောနစ် အချက်အလက်လွှင့်တင်ရေးစနစ် ကိုဆိုလိုသည်။</p>

                        <p className='lh-lg'>ကိုယ်ရေးကိုယ်တာအချက်အလက်ဆိုသည်မှာ အဆိုပါအချက်အလက်များမှဖြစ်စေ၊ အဆိုပါအချက်အလက်များနှင့် အဖွဲ့အစည်းတစ်ခု၏ပိုင်ဆိုင်သော သို့မဟုတ် သုံးစွဲပိုင်ခွင့်ရှိသောတခြားသတင်းအချက်အလက်များမှဖြစ်စေ ပုဂ္ဂိုလ် တစ်ဦးတစ်ယောက်အကြောင်းကိုသိရှိစေနိုင်သော အချက်အလက်များကိုဆိုလိုသည်။</p>

                        <p className='lh-lg'>ဖြည့်သွင်းသူများဆိုသည်မှာ အမျိုးသားညီညွတ်ရေးအစိုးရမှ ကောက်ခံသည့် အချက်အလက်များကို အီလက်ထရောနစ်အချက်အလက်ကောက်ယူရေးစနစ်မှဖြစ်စေ၊ အီးမေးလ်မှဖြစ်စေ အချက်အလက်များကိုဖြည့်သွင်းကြသူများကိုဆိုလိုသည်။</p>

                        <p className='lh-lg'>သဘောတူညီချက်များဆိုသည်မှာ ဤသဘောတူညီချက်တွင်ဖော်ပြထားသော အီလက်ထရောနစ်အချက်အလက်ကောက်ယူရေးစနစ် ဆိုင်ရာ သဘောတူညီချက်များနှင့် ကိုယ်ရေးကိုယ်တာအချက်အလက်ကောက်ယူခြင်းဆိုင်ရာ သဘောတူညီချက်များကို ဆိုလိုသည်။ </p>
                    </div>
                    <div className='tnctxt container'>
                        <h4 className='tnctxttitle lh-lg'>၂။ ကိုယ်ရေးအချက်အလက်များအားကာကွယ်ခြင်းနှင့် ဖျက်ဆီးခြင်း</h4>
                        <p className='lh-lg'>အီလက်ထရောနစ်အချက်အလက်ကောက်ယူရေးစနစ်တွင် ကိုယ်ရေးကိုယ်တာ အချက်အလက်များကို အပြည့်အဝလုံခြုံစွာကာကွယ်နိုင်ရန် သင့်တင့်သောလုံခြုံရေးအစီအမံများ ထားရှိထားပါသည်။ သို့သော် အခြေအနေအရပ်ရပ်ကြောင့် လုံခြုံရေးကျိုးပေါက်မှုများသည် ကျွန်ုပ်တို့၏ထိန်းချုပ်နိုင်စွမ်းကို ကျော်လွန်သွားပါက ကျွန်ုပ်တို့အနေဖြင့် တာဝန်ယူနိုင်သည်ဟု မယူဆနိုင်ပါ။</p>
                    </div>
                    <div className='tnctxt container'>
                        <h4 className='tnctxttitle lh-lg'>၃။ သိရှိနားလည်ခြင်းနှင့် သဘောတူခွင့်ပြုခြင်း</h4>
                        <p className='lh-lg'>အီလက်ထရောနစ်အချက်အလက်ကောက်ယူရေးစနစ်မှဖြစ်စေ၊ အီးမေးလ်မှဖြစ်စေ အချက်အလက်များကိုဖြည့်သွင်းကြသူများအနေဖြင့် ဤသဘောတူညီချက်တွင်ဖော်ပြထားသည့် အချက်များအားဖတ်ရှုနားလည်၍ သဘောတူလက်ခံပါသည်။</p>
                    </div>
                    <div className='tnctxt container'>
                        <h4 className='tnctxttitle lh-lg'>၄။ ဆက်သွယ်ရန်</h4>
                        <p className='lh-lg'>အမျိုးသားညီညွတ်ရေးအစိုးရ၏ ဝဘ်ဆိုဒ်များတွင်ပါဝင်သည့် Contact Us ဖောင်အား အသုံးပြု၍သော်လည်းကောင်း ဆက်သွယ်နိုင်ပါသည်။</p>
                    </div>
                </div>
            </Container>
        );
    }
} 